<template>
  <div class="card" style="min-height: 200px">
    <div class="row mt-2">
      <div class="col-10"></div>
      <div class="col-2">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="flexSwitchCheckChecked"
                 v-model="showHeader"
                 checked
          >
          <label class="form-check-label" for="flexSwitchCheckChecked">Include Header</label>
        </div>
      </div>
    </div>
    <vue3-html2pdf
      :show-layout="true"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      pdf-content-width="100%"
      :htmlToPdfOptions="htmlToPdfOptions"
      ref="html2Pdf"
    >
      <template  v-slot:pdf-content>
        <div class="card-body">
          <div class="p-1" v-if="showHeader">
            <div class="row">
              <div class="col-12 col-lg-6">
                <img :src="company.logo64" :alt="company.name" class="img-fluid" style="max-height: 100px;"/>
              </div>
              <div class="col-12 col-lg-6 text-right mt-2" v-if="company.name">
                <p class="h4">{{ company.name }}</p>
                <p>
                  Address: {{company.address}}, <br>
                  Phone: {{company.phone}}, <br>
                  E-mail: {{company.email}}
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 px-2">
            <p class="h1 m-0 text-center">
              Purchase Order
            </p>
            <hr>
          </div>
          <div class="row p-2">
            <div class="col-12 col-lg-12">
              <p>
                <strong>PO No: </strong> {{poData.po_number}} <br>
                <strong>PO Date: </strong> {{poData.po_date}}
              </p>
            </div>

            <div class="col-12 col-lg-12">
              <p>
                <strong> Supplier Details </strong> <br>
                <span v-if="poData.contact_profile?.profile_items.length > 0">{{poData.contact_profile?.profile_items[0]?.value}}</span> <br>
              </p>
            </div>

            <div class="col-12 col-lg-12">
              <p><strong>Kind Attention: </strong> {{poData.contact_profile?.full_name}}</p>
            </div>

            <div class="col-12 col-lg-12">
              <p>
                Dear Concern, <br>
                Please refer to your offer and the subsequent discussion, we had with you. Your offer is accepted at a cost of negotiated price as mentioned below with mentioned terms & conditions in the purchase order.
              </p>
            </div>

            <div class="col-12 table-responsive py-2">
              <po-table
                :table-items="tableItems">
              </po-table>
            </div>

            <div class="col-12">
              <p>
                <strong>In word:  </strong>
                {{ getTotal(tableItems, 'total_amount') > 0.00 ? currencyToWord(getTotal(tableItems, 'total_amount')) : '' }}
              </p>
            </div>

            <div class="col-12">
              <strong> Terms & Conditions: </strong> <br>
              {{poData.description}}
            </div>

            <div class="col-12 mt-3">
              <p>With best regards </p>
            </div>

            <div class="col-12 col-lg-3 mt-2">
              <p class="mb-3">
                <strong>Prepared By: </strong>
              </p>
              <p v-if="poData.user">
                {{poData.user.name}} <br>
              </p>
            </div>
            <div class="col-12 col-lg-3 mt-2 text-center">
              <p class="mb-3">
                <strong>Reviewed By: </strong>
              </p>

            </div>
            <div class="col-12 col-lg-3 mt-2 text-center">
              <p class="mb-3">
                <strong>Reviewed By: </strong>
              </p>

            </div>
            <div class="col-12 col-lg-3 mt-2 text-right">
              <p class="mb-3">
                <strong>Approved By: </strong>
              </p>

            </div>
          </div>
        </div>
      </template>
    </vue3-html2pdf>
    <vue3-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="false"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        pdf-content-width="100%"
        :htmlToPdfOptions="htmlToPdfOptions"
        ref="html2PdfPrint"
    >
      <template  v-slot:pdf-content>
        <div class="card-body">
          <div class="p-1" v-if="showHeader">
            <div class="row">
              <div class="col-12 col-lg-6">
                <img :src="company.logo64" :alt="company.name" class="img-fluid" style="max-height: 100px;"/>
              </div>
              <div class="col-12 col-lg-6 text-right mt-2" v-if="company.name">
                <p class="h4">{{ company.name }}</p>
                <p>
                  Address: {{company.address}}, <br>
                  Phone: {{company.phone}}, <br>
                  E-mail: {{company.email}}
                </p>
              </div>
            </div>
          </div>
          <div class="col-12 px-2">
            <p class="h1 m-0 text-center">
              Purchase Order
            </p>
            <hr>
          </div>
          <div class="row p-2">
            <div class="col-12 col-lg-12">
              <p>
                <strong>PO No: </strong> {{poData.po_number}} <br>
                <strong>PO Date: </strong> {{poData.po_date}}
              </p>
            </div>

            <div class="col-12 col-lg-12">
              <p>
                <strong> Supplier Details </strong> <br>
                <span v-if="poData.contact_profile">{{poData.contact_profile?.full_name}}</span> <br>
              </p>
            </div>

            <div class="col-12 col-lg-12">
              <p><strong>Kind Attention: </strong> {{poData.contact_profile?.full_name}}</p>
            </div>

            <div class="col-12 col-lg-12">
              <p>
                Dear Concern, <br>
                Please refer to your offer and the subsequent discussion, we had with you. Your offer is accepted at a cost of negotiated price as mentioned below with mentioned terms & conditions in the purchase order.
              </p>
            </div>

            <div class="col-12 table-responsive py-2">
              <po-table
                  :table-items="tableItems">
              </po-table>
            </div>

            <div class="col-12">
              <p>
                <strong>In word:  </strong>
                {{ getTotal(tableItems, 'total_amount') > 0.00 ? currencyToWord(getTotal(tableItems, 'total_amount')) : '' }}
              </p>
            </div>

            <div class="col-12">
              <strong> Terms & Conditions: </strong> <br>
              {{poData.description}}
            </div>

            <div class="col-12 mt-3">
              <p>With best regards </p>
            </div>

            <div class="col-12 col-lg-3 mt-2">
              <p class="mb-3">
                <strong>Prepared By: </strong>
              </p>
              <p v-if="poData.user">
                {{poData.user.name}} <br>
              </p>
            </div>
            <div class="col-12 col-lg-3 mt-2 text-center">
              <p class="mb-3">
                <strong>Reviewed By: </strong>
              </p>

            </div>
            <div class="col-12 col-lg-3 mt-2 text-center">
              <p class="mb-3">
                <strong>Reviewed By: </strong>
              </p>

            </div>
            <div class="col-12 col-lg-3 mt-2 text-right">
              <p class="mb-3">
                <strong>Approved By: </strong>
              </p>

            </div>
          </div>
        </div>
      </template>
    </vue3-html2pdf>
    <div class="card-footer">
      <button class="btn btn-primary float-end" @click="exportToPDF">Download as PDF</button>
      <button class="btn btn-primary float-end mr-5" @click="directPrint">Print</button>
    </div>
    <Loader v-if="loader"/>
  </div>
</template>
<script setup>
import handleCompany from "@/services/modules/company";
import Loader from "@/components/atom/LoaderComponent";
import PoTable from "@/components/atom/POTable";
import handleRequisitions from "@/services/modules/procurement/requisition";
import {onMounted, ref, inject, computed} from "vue";
import {useRoute} from "vue-router";
import Vue3Html2pdf from "vue3-html2pdf";
import figureFormatter from '@/services/utils/figureFormatter'

const company = ref({});
const loader = ref(false);
const showHeader = ref(true);
const poData = ref({});
const html2Pdf = ref(null);
const html2PdfPrint = ref(null);
const {fetchCompanyInfo} = handleCompany();
const {fetchSinglePO} = handleRequisitions();
const {commaFormat, currencyToWord} = figureFormatter();
const route = useRoute();
const showError =  inject('showError');
const po_number = computed(() => {
  return poData.value.po_number;
});
const htmlToPdfOptions = ref({
  margin: 0.5,
  filename: po_number,
  image: {
    type   : 'jpeg',
    quality: 0.98
  },
  enableLinks: false,
  html2canvas: {
    scale  : 1,
    useCORS: true
  },
  jsPDF: {
    unit       : 'in',
    format     : 'a4',
    orientation: 'portrait'
  }
});
const tableItems = computed(() => {
  return poData.value.purchase_general;
})
const exportToPDF = () => {
  html2Pdf.value.generatePdf();
}
const directPrint = () => {
  html2PdfPrint.value.generatePdf();
}

function setData(){
  poData.value.po_date  = new Date(poData.value.po_date).toISOString().split('T')[0];
}

function getTotal(data, column){
  let total = 0.00;
  if (data != undefined){
    data.forEach((item)=>{
      total += parseFloat(item[column]);
    })
  }
  return total;
}

onMounted(() => {
  loader.value = true;
  const companyQuery = `?company_id=${route.params.companyId}`;
  const fetchCompany = fetchCompanyInfo(route.params.companyId);
  const fetchPO = fetchSinglePO(route.params.poId, companyQuery);
  Promise.all([
    fetchCompany.then(res => {
      company.value = res.data;
    }),
    fetchPO.then(res => {
      poData.value = res.data;
      setData();
    })
  ])
      .then( () => loader.value = false )
      .catch( (err) => {
        showError("Something went wrong");
        loader.value = false;
      } );
})
</script>
