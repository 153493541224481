<template>
  <table class="table table-bordered">
    <thead>
    <tr>
      <th>#</th>
      <th>Description</th>
      <th>Unit</th>
      <th class="text-right">Qty</th>
      <th class="text-right">Unit Price</th>
      <th class="text-right">Amount</th>
      <th class="text-right">VAT Amount</th>
      <th class="text-right">Total Amount</th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(item, index) in tableItems" :key="index">
      <td>
        {{ ++index}}
      </td>
      <td>
          
        <span v-if="item.is_product == '1'">
          {{ item.requisition_general?.product?.name }}
        </span>
        <span v-else>
          {{ item.cs_general?.account_head?.name }}
        </span>
        <br>
        {{item.description}}
      </td>
      <td>
        <span v-if="item.is_product == '1'">
          {{ item.cs_general?.requisition_general?.product?.description?.unit?.name }}
        </span>
        <span v-else>-</span>
      </td>
      <td class="text-right">
        <span v-if="item.is_product == '1'">{{commaFormat(item.quantity)}}</span>
        <span v-else>-</span>
      </td>
      <td class="text-right">
        <span v-if="item.is_product == '1'">{{commaFormat(item.rate)}}</span>
        <span v-else>-</span>
      </td>
      <td class="text-right">{{commaFormat(item.amount)}}</td>
      <td class="text-right">{{commaFormat(item.vat_amount)}}</td>
      <td class="text-right">{{commaFormat(item.total_amount)}}</td>
    </tr>
    </tbody>
    <tfoot>
      <tr>
        <td colspan="5">Total</td>
        <td class="text-right">{{ getTotal(tableItems, 'amount')}}</td>
        <td class="text-right">{{ getTotal(tableItems, 'vat_amount')}}</td>
        <td class="text-right">{{ getTotal(tableItems, 'total_amount')}}</td>
      </tr>
    </tfoot>
  </table>
</template>
<style scoped>
.table thead th, .table tfoot th {
  vertical-align: top;
  text-transform: uppercase;
  font-size: 0.857rem;
  letter-spacing: 0px !important;
  padding: 0.5rem 0.5rem!important;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem !important;
}
</style>
<script setup>
import figureFormatter from '@/services/utils/figureFormatter'

const props  = defineProps({
  tableItems: Object,
});

const {commaFormat} = figureFormatter();

function getTotal(data, column){
  let total = 0.00;
  if (data != undefined){
    data.forEach((item)=>{
      total += parseFloat(item[column]);
    })
  }
  return commaFormat(total);
}
</script>
